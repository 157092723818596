
















































import { Component, Prop, Vue } from "vue-property-decorator";
import ConfiguratorConfigurationItemSelectionList from "@/components/configurator/configuration/step/item-selection/ConfiguratorConfigurationItemSelectionList.vue";
import {
    ConfiguratorArticle,
    ConfiguratorArticleGroup,
} from "@/store/modules/configurator";
import { ConfiguratorConfigurationSelectionItem } from "@/types/configurator";
import ShopCompleteArticleNumber from "@/components/shop/article/ShopCompleteArticleNumber.vue";

@Component({
    components: {
        ConfiguratorConfigurationItemSelectionList,
        ShopCompleteArticleNumber,
    },
})
export default class ConfiguratorOverviewStepByGroups extends Vue {
    @Prop({ type: Object, required: true })
    readonly articleGroup!: ConfiguratorArticleGroup;

    @Prop({ type: Object, required: false })
    readonly value!: ConfiguratorArticle | undefined;

    @Prop({ type: Number, required: false })
    readonly quantity!: number | undefined;

    private isDialogVisible = false;

    /**
     * @returns items for item selection list component
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get itemSelectionListItems(): ConfiguratorConfigurationSelectionItem[] {
        if (!this.articleGroup.articles) {
            return [];
        }

        return this.articleGroup.articles.map((article) => ({
            id: article.id,
            name: article.descriptions[0].title,
            image: article.images[0],
            cbData: article,
        }));
    }

    /**
     * Handler for article selection
     *
     * @param article article
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async articleSelected(id: number, article: ConfiguratorArticle) {
        this.$emit("input", article);
        this.isDialogVisible = false;
    }
}
