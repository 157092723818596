



































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import ConfiguratorModule, {
    ConfiguratorConfigurationStep,
    ConfiguratorConfigurationStepType,
    ConfiguratorArticle,
    ConfiguratorArticleProperty,
    ConfiguratorConfiguredConfiguration,
    ConfiguratorConfigurationStepResultType,
} from "@/store/modules/configurator";
import axios, { APIResponse } from "@/plugins/axios";
import { Property } from "@/types/property";
import AlertModule, { AlertType } from "@/store/modules/alert";
import AdminBaseItemCRUDDialog from "@/components/admin/base/item-management/AdminBaseItemCRUDDialog.vue";
import ArticlePropertyConfiguration from "@/components/shop/article/property/ArticlePropertyConfiguration.vue";
import ConfiguratorConfigurationStepByProperty from "@/components/configurator/configuration/step/property/ConfiguratorConfigurationStepByProperty.vue";
import ConfiguratorOverviewStepByGroups from "@/components/configurator/overview/step/group/ConfiguratorOverviewStepByGroups.vue";
import ConfiguratorOverviewStepByGroup from "@/components/configurator/overview/step/group/ConfiguratorOverviewStepByGroup.vue";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";
import ShopCompleteArticleNumber from "@/components/shop/article/ShopCompleteArticleNumber.vue";

/**
 * ConfiguratorOverview view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemCRUDDialog,
        ConfiguratorOverviewStepByGroups,
        ConfiguratorOverviewStepByGroup,
        ArticlePropertyConfiguration,
        ArticlePriceLabel,
        ConfiguratorConfigurationStepByProperty,
        ShopCompleteArticleNumber,
    },
})
export default class ConfiguratorOverview extends Vue {
    private articleTableSearchText = "";

    private isAdminMode = false;
    private commissionName = "";
    private configurationQuantity = 1;

    private isConfiguredConfigurationCRUDDialogVisible = false;
    private configuredConfigurationItem: ConfiguratorConfiguredConfiguration = {
        id: -1,
        number: "",
        categoryId: -1,
        configurationGroupId: -1,
        configuration: {
            stepResults: [],
            properties: [],
            additionalItems: [],
            image: {
                id: -1,
                alt: "",
                src: "",
            },
            price: null,
            description: "",
        },
        steps: [],
    };

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        // Load configuration when vuex store is empty
        if (ConfiguratorModule.selectedCategoryId === -1) {
            ConfiguratorModule.loadLastConfigurationState();
        }

        // Redirect to configurator mainpage when not all steps are completed
        if (
            ConfiguratorModule.completedSteps !==
            ConfiguratorModule.configurationSteps.filter(
                (step) => step.order != null
            ).length +
                2
        ) {
            this.$router.push({ name: "configurator" });
            return;
        }

        // Fetch configuration details
        if (
            ConfiguratorModule.configuration.price == null ||
            ConfiguratorModule.configuration.image == null
        ) {
            ConfiguratorModule.fetchConfigurationDetails();
        }
    }

    /**
     * Updates article property for specified step
     * Updates configuratorOverviewConfigurationResults variable
     *
     * @param step step number
     * @param property updated property
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async updateArticleProperty(
        step: number,
        property: ConfiguratorArticleProperty
    ) {
        await ConfiguratorModule.updateArticlePropertyForStepResult({
            step,
            property,
        });
        ConfiguratorModule.validateConfigurationStepResults(step);
    }

    /**
     * Updates configuration property
     * Updates configuratorOverviewConfigurationResults variable
     *
     * @param property updated property
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async updateConfigurationProperty(
        property: ConfiguratorArticleProperty
    ) {
        await ConfiguratorModule.updateConfigurationProperty(property);
        ConfiguratorModule.validateConfigurationStepResults();
    }

    /**
     * Handler when group was selected
     * Set group for current step and go to next step
     *
     * @param article article
     * @param step step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async setStepByGroupArticle(
        article: ConfiguratorArticle | undefined,
        step: number
    ) {
        if (article !== undefined) {
            await ConfiguratorModule.setArticleForStep({
                article,
                step,
                order: null,
            });
        } else {
            await ConfiguratorModule.removeItemForStep(step);
        }
        await ConfiguratorModule.validateConfigurationStepResults(step);
    }

    /**
     * Add configuration to cart
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async addToCart() {
        try {
            const data = {
                configuration: ConfiguratorModule.configuration,
                name: this.commissionName,
                quantity: this.configurationQuantity,
                currentStep: ConfiguratorModule.currentStep,
            };

            const response = await axios.post<APIResponse>(
                `/configurator/group/${ConfiguratorModule.selectedConfigurationGroupId}/addtocart`,
                data
            );
            if (response.data.status === "error") {
                throw new Error(response.data.message || "unknownError");
            }

            ConfiguratorModule.startConfiguration();
            this.$router.push({
                name: "shoppingCart",
            });
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Set configuredConfigurationItem and set isConfiguredConfigurationCRUDDialogVisible to truSet configuredConfigurationItem and set isConfiguredConfigurationCRUDDialogVisible to true
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addConfiguredConfiguration() {
        this.configuredConfigurationItem = {
            id: -1,
            number: "",
            categoryId: ConfiguratorModule.selectedCategoryId,
            configurationGroupId:
                ConfiguratorModule.selectedConfigurationGroupId,
            configuration: {
                stepResults: this.configurationStepResults,
                properties: this.configurationProperties,
                additionalItems: this.configurationAdditionalItems,
                image: this.configurationImage,
                price: null,
                description: "",
            },
            steps: [],
        };
        this.isConfiguredConfigurationCRUDDialogVisible = true;
    }

    /**
     * Function to call function and return result or undefined when an error occours
     * Can be used for optional chaining etc.
     *
     * @returns fn result or undefined when an error occours
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private getSafe(fn: () => any) {
        try {
            return fn();
        } catch (e) {
            return undefined;
        }
    }

    /**
     * @param step step
     *
     * @returns stepResult for given step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private getStepResultByStep(step: number) {
        return ConfiguratorModule.configuration.stepResults.find(
            (sr) => sr.step === step
        );
    }

    /**
     * @param step step
     *
     * @returns configured item from stepResult for given step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private getConfiguredItemFromStepResultsByStep(step: number) {
        return ConfiguratorModule.configuration.stepResults.find(
            (sr) => sr.step === step
        )?.configuredItem;
    }

    /**
     * @returns selected configurationGroup id
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get selectedConfigurationGroupId(): number {
        return ConfiguratorModule.selectedConfigurationGroupId;
    }

    /*
     * @returns global property steps
     *
     * @param Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationGlobalPropertySteps(): ConfiguratorConfigurationStep<
        ConfiguratorArticleProperty[]
    >[] {
        const globalPropertySteps: ConfiguratorConfigurationStep<
            ConfiguratorArticleProperty[]
        >[] = [];

        ConfiguratorModule.configurationSteps
            .filter(
                (step) =>
                    step.type ===
                    ConfiguratorConfigurationStepType.GlobalProperty
            )
            .forEach((step: ConfiguratorConfigurationStep<Property>) => {
                const stepData: ConfiguratorArticleProperty[] = [];

                step.data.units.forEach((unit) => {
                    stepData.push({
                        id: step.data.id,
                        names: [...step.data.names],
                        unit: unit,
                        value: step.data.needsValue
                            ? {
                                  id: step.data.id,
                                  value: 0,
                                  minValue: null,
                                  maxValue: null,
                              }
                            : null,
                    });
                });

                globalPropertySteps.push({
                    ...step,
                    data: stepData,
                });
            });

        return globalPropertySteps;
    }

    /**
     * @returns configuration steps
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationSteps() {
        return ConfiguratorModule.configurationSteps;
    }

    /**
     * @returns configuration step results
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationStepResults() {
        return ConfiguratorModule.configuration.stepResults;
    }

    /**
     * @returns configuration additional items
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationAdditionalItems() {
        return ConfiguratorModule.configuration.additionalItems;
    }

    /**
     * @returns configuration properties
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationProperties(): ConfiguratorArticleProperty[] {
        return ConfiguratorModule.configuration.properties;
    }

    /**
     * @returns configuration step types
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationStepTypes() {
        return ConfiguratorConfigurationStepType;
    }

    /**
     * @returns configuration stepresult types
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationStepResultTypes() {
        return ConfiguratorConfigurationStepResultType;
    }

    /**
     * @returns configuration image or null
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationImage() {
        return ConfiguratorModule.configuration.image;
    }

    /**
     * @returns configuration description
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationDescription() {
        return ConfiguratorModule.configuration.description;
    }

    /**
     * @returns configuration price or null
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get configurationPrice() {
        return ConfiguratorModule.configuration.price;
    }
}
