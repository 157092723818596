




import { Component, Prop, Vue } from "vue-property-decorator";
import { Article } from "@/types/shop";

@Component
export default class ShopCompleteArticleNumber extends Vue {
    @Prop({ type: Object, required: true }) readonly article!: Article;

    private get completeArticleNumber(): string {
        let articleNumber = this.article.number;
        this.article.properties.forEach((prop) => {
            if (prop.unit != null && prop.unit.idAdditive != null) {
                const idAdditive =
                    typeof prop.unit.idAdditive === "string"
                        ? prop.unit.idAdditive
                        : typeof prop.unit.idAdditive === "number" &&
                          prop.unit.idAdditive < 0
                        ? prop.unit.idAdditive.toString()
                        : "-" + prop.unit.idAdditive;

                // -200
                if (idAdditive[0] === "-") {
                    articleNumber += idAdditive;
                }
                // 200-
                else if (idAdditive.slice(-1) === "-") {
                    articleNumber = idAdditive + articleNumber;
                }
            }
        });

        return articleNumber;
    }
}
