












































import { Component, Prop, Vue } from "vue-property-decorator";
import {
    ConfiguratorArticleGroup,
    ConfiguratorArticle,
} from "@/store/modules/configurator";
import ShopCompleteArticleNumber from "@/components/shop/article/ShopCompleteArticleNumber.vue";

/**
 * ConfiguratorOverviewStepByGroup component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ShopCompleteArticleNumber,
    },
})
export default class ConfiguratorOverviewStepByGroup extends Vue {
    @Prop({ type: Object, required: true })
    readonly articleGroup!: ConfiguratorArticleGroup;

    @Prop({ type: Object, required: false })
    readonly value!: ConfiguratorArticle | undefined;

    @Prop({ type: Number, required: false })
    readonly quantity!: number | undefined;

    /**
     * Handler for article selection
     *
     * @param input input
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async inputChanged(input: ConfiguratorArticle | undefined) {
        this.$emit("input", input);
    }
}
